﻿import {
    $,
    $$,
    centerActiveTab,
    get,
    gtmEvent, 
    gtmSimpleEvent,
    hide,
    Key,
    postDataText,
    show,
    toggleTargetClosestSelector
} from "@/Utils"
import {initComparisons, initFavorites} from "@/ProductModule"
import {initSlider} from "@/sharedComponents/Slider/Slider"
import {initDropdowns} from "@/sharedComponents/Dropdown/Dropdown"
import {initTooltips} from "@/sharedComponents/Tooltip";
import {GtmEventType, PageType} from "@/enums";
import {closePanel} from "@/sharedComponents/Panel";
import {initCarousels} from "@/sharedComponents/Carousel/Carousel";
import {GtmFilterOptions} from "@/interfaces";


function initHeaderAndFooterDescription() {
    const infoTexts = $$(".category-description__text");
    infoTexts.forEach(infoText => {
        const readMore = infoText?.parentElement?.querySelector(".read__more") as HTMLElement;
        const readLess = infoText?.parentElement?.querySelector(".read__less") as HTMLElement;

        if (!infoText || !readMore) return;

        const headerRight = readMore.parentElement;
        // Hide read-more button when we do not have another line height worth of content. 2 is a magical constant that just works!
        const isDescriptionLongerThanContainer = infoText.scrollHeight - 2 > infoText.clientHeight;
        if (!isDescriptionLongerThanContainer) {
            hide(readMore);
        }
        readMore.addEventListener("click", () => headerRight.classList.add("category-description--expanded"));
        readLess.addEventListener("click", (e) => {
            headerRight.classList.remove("category-description--expanded");
            (e.target as HTMLElement).closest(".category-description").scrollIntoView({behavior: 'smooth', block: 'nearest'})
        });
    })
}

function filterEventListener(event: Event) {
    const target = event.currentTarget as Element;
    const showAllFilters = target.classList.contains("sort-menu__jumplist");
    toggleTargetClosestSelector(target, "li", "sort-menu__item--open", true,  showAllFilters ? null : 7);
    
}

function openMore(event: Event) {
    const target = (event.target as HTMLElement).closest("ul");
    target.querySelectorAll("li").forEach(x => show(x));
    hide((event.target as HTMLElement).closest("li"));
}

function getSelectedFilters(filterAction: boolean): GtmFilterOptions {

    const filterOptions: GtmFilterOptions = {
        filterAction: filterAction ? "add" : "remove",
        filterCount: 0,
        filters: {}
    }
    $$(".sort-menu__item[data-filter-title]").forEach(x => {
        const activeSubs = []
        x.querySelectorAll("[data-filter-subtitle]").forEach(y => {
            if (!y.querySelector("input").checked) return

            activeSubs.push(y)
        })
        if (!activeSubs.length) return

        const parentTitle = x.getAttribute("data-filter-title")
        filterOptions.filters[parentTitle] = []
        const parentFilter = filterOptions.filters[parentTitle]
        activeSubs.forEach(y => {
            parentFilter.push(y.getAttribute("data-filter-subtitle"))
            filterOptions.filterCount++
        })
    })
    return filterOptions
}

function responsiveCategoryLayout() {
    const desktopContainer = $(".category__wrapper")
    if(!desktopContainer) return; // Search results missing
    const responsiveContainer = get("category-filter-panel").querySelector(".panel-right")
    const responsiveSort = $(".responsive-sort .sort-menu__list")
    let desktopLeftSidebarSelector = ".category__wrapper--left";

    if (window.isResponsive) {
        let sortMenuItem;
        if (get(PageType.GroupLandingPage)) {
            // Copy categories from the desktop jumplist.
            sortMenuItem = desktopContainer.querySelector(".sort-menu__list.group-landingpage");
            responsiveSort.append(sortMenuItem);
        } else {
            //Copy categories from the filters block.
            sortMenuItem = desktopContainer.querySelector(".sort-menu__item.category-jumplist");
            if (sortMenuItem) {
                const placeholder = document.createElement("div");
                placeholder.id = "jumplist__placeholder";
                sortMenuItem.replaceWith(placeholder)
                responsiveSort.append(sortMenuItem)
            }
        }

        const wrapper = desktopContainer.querySelector(desktopLeftSidebarSelector)
        if (!wrapper) return;
        // RespnsiveContainer is filters panel which is hidden to the left of viewport.
        responsiveContainer.append(wrapper)
        initHorizontalScroll()
        centerActiveTab();
    } else {
        const wrapper = responsiveContainer.querySelector(desktopLeftSidebarSelector)
        if (!wrapper) return
        const desktopRightContainer = desktopContainer.querySelector(".category__wrapper--right")
        desktopContainer.insertBefore(wrapper, desktopRightContainer)

        const sortMenuItem = responsiveSort.querySelector(".sort-menu__item.category-jumplist")
        if (!sortMenuItem) return

        const placeholder = get("jumplist__placeholder")
        placeholder.replaceWith(sortMenuItem)

        if ($("#category-filter-panel.panel__wrapper--active")) {
            closePanel()
        }
    }
}

function initFilters() {
    $$(".custom-radio__input").forEach((x: HTMLInputElement) => x.checked = x.hasAttribute("checked"));

    $$("button.sort-menu__button").forEach(elem => {
        elem.addEventListener("click", filterEventListener);
    });
    $$(".show-more").forEach(x => x.addEventListener("click", openMore));

    $$("[data-filter]").forEach(x => {
        const filterCheckbox = x as HTMLInputElement
        filterCheckbox.checked = filterCheckbox.hasAttribute("checked")
        x.addEventListener("change", () => {
            if(window.google_tag_manager === undefined){
                window.location.href = x.getAttribute("data-url")
                return
            }
            const selectedFilters = getSelectedFilters(filterCheckbox.checked)
            const action = filterCheckbox.closest("[data-filter-title]").getAttribute("data-filter-title")
            const label = filterCheckbox.closest("[data-filter-subtitle]").getAttribute("data-filter-subtitle")
            const callback = (containerId : string) => {
                // Fired for every container. We just need one.
                if (containerId?.startsWith("GTM-")) {
                    window.location.href = x.getAttribute("data-url")
                }
            }
            gtmEvent(GtmEventType.Filter, action, label, callback, selectedFilters, null)
        })
    });
}

function initSortDropdown() {
    const ddl = $("#list-sort-dropdown")
    if (ddl) {
        initDropdowns()
        ddl.addEventListener("valueChange", (ev: CustomEvent<HTMLInputElement>) => {
            const callback = () => {
                window.location.href = ev.detail.getAttribute("value")
            }
            gtmSimpleEvent(GtmEventType.Sort, ev.detail.getAttribute("data-filter-title"), callback)
        })
    }
}

function bindPriceFilter() {
    const min = $("[data-pmin]") as HTMLInputElement;
    const max = $("[data-pmax]") as HTMLInputElement;
    if (!min || !max) return;

    const filterSubmit = $(".price-filter__wrapper").parentElement.querySelector(".submit__filter") as HTMLElement;

    min.value = min.defaultValue
    max.value = max.defaultValue

    const urlTemplate = ($(".price-filter__wrapper").querySelector("input[name=urltemplate]") as HTMLInputElement).value;

    [min, max].forEach((el) => el.addEventListener("input", () => {
        const minValue = +min.value;
        const maxValue = +max.value;
        filterSubmit.closest(".submit__filter__wrapper").classList.toggle("submit__filter__wrapper--visible", minValue >= 0 || maxValue >= 0);

        filterSubmit.setAttribute("href", urlTemplate + "_" + minValue + "_" + maxValue);
    }));

    [min, max].forEach((el) => el.addEventListener("keydown",
        (e) => {
            if (e.key === Key.Enter) filterSubmit.click();
        }));
}

function updateRelNext(responseHtml: HTMLElement) {
    const relNext = responseHtml.querySelector("[data-rel-next]")?.getAttribute("data-rel-next");
    let linkRelNext = $("link[rel=next]") as HTMLLinkElement;
    if (relNext) {
        if (!linkRelNext) {
            linkRelNext = document.createElement('link')
            linkRelNext.rel = "next"
            document.head.appendChild(linkRelNext)
        }
        linkRelNext.href = relNext
    } else {
        linkRelNext?.remove()
    }
    
    const loadMoreElement = document.getElementById("nextAnchor") as HTMLAnchorElement;
    if (loadMoreElement){
        loadMoreElement.href = relNext;
    }
}

function bindLoadMoreButton() {
    const listSection = $(".product-card__list") as HTMLElement;
    if (!listSection) return; // "no results" leht

    const scrollToTop = get("scroll-to-top");
    if (listSection.childElementCount > window.config.listSize)
        scrollToTop.classList.add("scroll-to-top-container--active");

    const button = $(".loading-button") as HTMLButtonElement;
    if (!button) return;

    const buttonText = button.querySelector(".button__text") as HTMLElement;
    const loadingAnimation = button.querySelector("[data-loader]") as HTMLElement;
    const moreFilter = $("[data-new-filter]");

    const startLoading = () => {
        hide(buttonText);
        show(loadingAnimation);
    }

    const stopLoading = () => {
        show(buttonText);
        hide(loadingAnimation);
        scrollToTop.classList.add("scroll-to-top-container--active");
    }

    const updateUrlFilter = (filter: string) => {
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.set("f", filter);
        urlParams.set("p", "1");
        const newUrl = window.location.origin + window.location.pathname + "?" + urlParams.toString();

        history.replaceState({}, document.title, newUrl);
    }

    function updateRobotsMeta(div: HTMLDivElement) {
        const disallowRobotsFromResponse = div.querySelector("[data-disallow-robots]")?.getAttribute("data-disallow-robots");
        if (disallowRobotsFromResponse == 'True') {
            let robotsInDocument = $("meta[name=robots]") as HTMLMetaElement;
            robotsInDocument.content = "noindex, nofollow";
            let linkRelCanonical = $("link[rel=canonical]") as HTMLLinkElement;
            if (linkRelCanonical){
                linkRelCanonical.remove()
            }
        }
    }

    const afterLoadMore = (data: string) => {
        const pageYoffset = window.pageYOffset;
        const div = document.createElement("div");
        div.innerHTML = data;
        const products = div.querySelectorAll("#more-products .product-card");
        products.forEach(p => listSection.append(p));
        const currentFilter = moreFilter.getAttribute("data-new-filter");

        const newFilter = div.querySelector("[data-new-filter]")?.getAttribute("data-new-filter");
        if (newFilter) {
            moreFilter.setAttribute("data-new-filter", newFilter);
        } else {
            button.style.display = "none";
        }
        const relPrev = div.querySelector("[data-rel-prev]")?.getAttribute("data-rel-prev")
        let linkRelPrev = $("link[rel=prev]") as HTMLLinkElement;
        if (relPrev) {
            if (!linkRelPrev) {
                linkRelPrev = document.createElement('link')
                linkRelPrev.rel = "prev"
                document.head.appendChild(linkRelPrev)
            }
            linkRelPrev.href = relPrev
        } else {
            linkRelPrev?.remove()
        }

        updateRobotsMeta(div);
        updateRelNext(div);

        document.body.style.overflowAnchor = "none";
        window.scrollTo({
            top: pageYoffset,
            behavior: 'smooth'
        });

        updateUrlFilter(currentFilter);
        let linkRelCanonical = $("link[rel=canonical]") as HTMLLinkElement;
        if (linkRelCanonical) {
            let canonical = window.location.href;
            if (canonical.endsWith("&p=1")) canonical = canonical.substring(0, canonical.length - 4);
            linkRelCanonical.href = canonical;
        }
        initComparisons();
        initFavorites();
        stopLoading();
    }

    button.addDataBoundEventListener("click",
        () => {
            startLoading();
            const filter = moreFilter.getAttribute("data-new-filter");
            const isCampaignLabelTextAlreadyOpen = $(".free-campaign-icon") !== null;
            postDataText(window.location.origin + window.location.pathname + "?f=" + filter, isCampaignLabelTextAlreadyOpen, afterLoadMore);
        });
}

function bindScrollers() {

    window.addEventListener("resize", () => {
        centerActiveTab();
    });

    get("scroll-to-top")?.addEventListener("click", () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    });

    let lastScrollTop = 0;
    window.addEventListener("scroll",() => {
        let scrollTop = window.scrollY || document.documentElement.scrollTop;
        let filter = $(".sort-and-filter");
        let sort = $(".responsive-sort");
        let padding = $(".responsive-sticky-padding");
        let header = $(".header");
        let paddingVolume = filter?.offsetHeight + sort?.offsetHeight;
        
        if (scrollTop < lastScrollTop && scrollTop > header.offsetHeight) {
            filter?.classList.add("sort-and-filter-sticky")
            sort?.classList.add("responsive-sort-sticky")
            padding.style.height = paddingVolume + "px";
        } else {
            filter?.classList.remove("sort-and-filter-sticky")
            sort?.classList.remove("responsive-sort-sticky")
            padding.style.height = "";
        }
        lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // For mobile or negative scrolling
    }, false);
}

const scrollObserver = new IntersectionObserver(entries => {
    const scroller = $(".scroll-category")
    entries.forEach(e => {
        if (scroller) {
            if (e.isIntersecting) {
                scroller.classList.remove("d-flex")
            } else {
                scroller.classList.add("d-flex")
            }
        }
    })
}, {
    root: $(".responsive-sort .sort-menu__item.category-jumplist"),
    rootMargin: '-1px',
    threshold: [1]
})

function initHorizontalScroll() {
    const sortList = $(".responsive-sort .sort-menu__sub.category-jumplist")
    if (!sortList || sortList.hasAttribute("data-bound")) return
    sortList.setAttribute("data-bound", "")
    scrollObserver.observe(sortList.lastElementChild as Element)
}

window.addEventListener("load", () => {
    initSlider()
    initSortDropdown()
    initComparisons()
    bindPriceFilter()
    bindLoadMoreButton()
    initFavorites()
    initTooltips()
    bindScrollers()
    initFilters()
    window.responsiveObserverFunctions = (window.responsiveObserverFunctions || [])
    window.responsiveObserverFunctions.push(responsiveCategoryLayout)
    initHeaderAndFooterDescription()
    initCarousels();
})